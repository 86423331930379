.form-table {
  @apply border border-black bg-gray-200 w-1/2;
}
.form-table td {
  @apply border border-black;
}
.form-table td[colspan="2"] {
  @apply bg-gray-500 text-white font-bold text-center;
}
.form-table td:nth-child(even) {
  @apply text-center items-center;
}
.form-table input {
  @apply rounded-md p-0.5;
}
.form-table tr td {
  @apply w-1/2;
}
.form-table select {
  @apply border bg-gray-100 border-black rounded-md;
}
