.basic-t {
  @apply border-separate border-spacing-0 overflow-x-auto w-full;
}

.basic-t td,
.basic-t th {
  @apply border-t-2 border-t-gray-400 text-center border-r-2 border-r-gray-400;
}
.basic-t tr td:first-child,
.basic-t tr th:first-child {
  @apply border-l-2 border-l-gray-400;
}
.basic-t tr:last-child td {
  @apply border-b-2 border-b-gray-400;
}
.basic-t thead tr th {
  @apply bg-gray-200;
}

.basic-t tbody tr:nth-child(odd) td {
  @apply bg-gray-300  bg-opacity-80;
}
.basic-t tbody tr:nth-child(even) td {
  @apply bg-gray-100 bg-opacity-80;
}
.basic-t thead tr {
  z-index: 1;
  @apply sticky top-0;
}
.basic-t tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
  overflow: hidden;
}
.basic-t tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.basic-t tr:first-child th:first-child {
  border-top-left-radius: 10px;
  overflow: hidden;
}
.basic-t tr:first-child th:last-child {
  border-top-right-radius: 10px;
  overflow: hidden;
}
