.row {
  @apply flex items-center;
}
.row :first-child {
  @apply w-2/4;
}
.row :last-child {
  @apply w-2/4;
}
.row-input {
  @apply border-2 rounded-md h-fit;
}
