@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  scroll-behavior: none; overflow: hidden;
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
}
:root {
  --primary: #004b82;
  --secondary: #0572c0;
}
.bg-main {
  background-color: var(--primary);
}
.svg-bg {
  background-image: url(/public/subtle-prism.svg);
}
.d-table {
  @apply border-separate border-spacing-0 w-full rounded-lg border border-gray-400  bg-opacity-50;
}
.d-tr {
  @apply text-center;
}
.d-table tr {
  @apply text-center;
}
.d-tr:nth-child(even) {
  @apply bg-gray-50 bg-opacity-80;
}
.d-tr:nth-child(odd) {
  @apply bg-gray-100 bg-opacity-80;
}
.d-td,
.d-th {
  @apply border border-gray-300  py-0.5;
}
.d-th {
  @apply bg-gray-200;
}
.d-thead {
  @apply sticky top-0 bg-white;
}
.form-row {
  display: flex;
  gap: 1rem;
}
.form-row * {
  width: fit-content;
  word-wrap: normal;
}
